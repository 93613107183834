<template>
  <div class="Sales">
    <div
      style="
        padding-bottom: 100px;
        height: 100%;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
      "
    >
      <div class="top" style="">
        <div class="flex">
          <el-select
            v-model="selectKey"
            style="width: 130px"
            @change="selectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="placeholder"
            v-model="queryParam.Keywords"
            @input="inputChange"
            @keydown.enter.native="search"
          ></el-input>
        </div>
        <!-- <div class="flex" style="margin-top: 10px">
          <el-date-picker v-model="StartTime" type="date" placeholder="开始日期" @change="startTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-date-picker v-model="EndTime" type="date" placeholder="结束日期" @change="endTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div> -->
      </div>
      <div class="table-wrapper">
        <!--   -->
        <el-table
          ref="queryForm"
          :height="tableHeight"
          element-loading-text="拼命加载中"
          :data="tableData"
          v-loading="loading"
          v-el-table-infinite-scroll="scrollLoad"
          :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="scrollDisaled"
        >
          <el-table-column
            align="left"
            :prop="item.prop"
            :label="item.label"
            v-for="(item, index) in tablePropListData"
            :fixed="item.label === '客户'"
            :key="index"
          >
          </el-table-column>
        </el-table>
        <!-- <div v-if="tableData.length == pagination.total" style="text-align:center;padding-top:10px">没有更多了</div> -->
      </div>
    </div>

    <div
      style="
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 20px;
        background-color: #fff;
        display: flex;
      "
    >
      <div style="flex: 5">总计:</div>
      <div style="flex: 5">{{ toThousands(QIANMONEY) }}</div>
    </div>
  </div>
</template>

<script>
import { toThousands, getMonthRange, formatDate } from "../../../utils/Tools";
export default {
  data() {
    return {
      scrollDisaled: false,
      tableHeight: 200,
      getMonthRange,
      // 千分位格式化
      toThousands,
      // 下拉选择数据
      options: [
        {
          value: "0",
          label: "客户",
        },
        {
          value: "1",
          label: "业务员",
        },
      ],
      // 表格属性数据
      tablePropListData: [
        {
          label: "客户",
          prop: "Name",
        },
        {
          label: "欠款金额",
          prop: "QIANMONEY",
        },
      ],
      isFirst: true, // 是否第一次加载
      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户名称进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        currentSkipCount: 0, //当前显示
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: { CompanyId: "", Keywords: "", Type: 0 },
      // 欠款总金额
      QIANMONEY: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
        document.documentElement.clientHeight -
        this.$refs.queryForm.$el.offsetHeight;
      this.tableHeight = height + 40;
    });

    // 获取屏幕宽高
    let availHeight = window.screen.availHeight;
    let availWidth = window.screen.availWidth;
    console.log("高度", availHeight, availWidth);
  },
  created() {
    document.title = "欠款统计";

    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId;
    this.pagination.token = this.$route.query.token;

    this.toGetList();
  },
  methods: {
    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取欠款统计列表数据
      this.getCollectionStatistics();
      // 获取欠款统计总金额数据
      this.GetDebtLinqAllStatistics();
    },
    // 搜索
    search() {
      // 同步获取欠款列表统计数据和总金额统计数据
      this.toGetList();
    },
    loadDataListfilter() {
      this.isFirst = false;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.pagination.total = 0;
      // 获取欠款列表统计数据
      this.getCollectionStatistics();
    },

    // 获取列表收款统计接口
    GetDebtLinqAllStatistics() {
      this.$http
        .post("/BO/BIlling/GetDebtLinqAllStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log("获取欠款统计", resJson.Data);
            if (resJson.Data == null) {
              this.QIANMONEY = 0;
              return;
            }
            this.QIANMONEY = resJson.Data.QIANMONEY;
          } else {
            this.loading = false;
          }
        });
    },

    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (
        this.pagination.current > 1 &&
        this.tableData.length == this.pagination.total
      ) {
        return;
      }
      // 获取欠款统计列表数据
      this.getCollectionStatistics();
    },
    // 时间选择
    startTimeChange(e) {
      console.log("开始时间", e);
      this.queryParam.StartDate = e;
      // 同步获取欠款列表统计数据和总金额统计数据
      this.toGetList();
    },
    endTimeChange(e) {
      console.log("结束时间", e);
      this.queryParam.EndDate = e;
      // 同步获取欠款列表统计数据和总金额统计数据
      this.toGetList();
    },
    // 搜索输入框输入
    inputChange(e) {
      console.log("搜索框输入", e);
      this.queryParam.Keywords = e;
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.getCollectionStatistics();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getCollectionStatistics();
    },
    // 获取欠款统计列表数据
    getCollectionStatistics() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetDebtLinqStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
            if (resJson.Data.length == 0) {
              this.scrollDisaled = true;
              return;
            }

            this.scrollDisaled = false;

            if (resJson.Data) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }

            resJson.Data.forEach((item) => {
              item.QIANMONEY = toThousands(item.QIANMONEY);
            });

            this.tableData = this.tableData.concat(resJson.Data);

            if (
              this.pagination.current * this.pagination.pageSize >=
              this.pagination.total
            ) {
              return;
            }
          } else {
            this.loading = false;
            this.$message.error(resJson.Msg);
          }
        });
    },
    // 下拉选择事件
    selectChange(e) {
      this.queryParam.Keywords = "";
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      console.log(e);
      if (e == 0) {
        this.tablePropListData[0].label = "客户";
        this.placeholder = "请输入客户名称进行查询";
      } else {
        this.tablePropListData[0].label = "业务员";
        this.placeholder = "请输入业务员名称进行查询";
      }
      this.queryParam.Type = Number(e);
      // 同步获取欠款列表统计数据和总金额统计数据
      this.toGetList();
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  .table-wrapper {
    margin-top: 10px;
  }

  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
}
</style>
